html,
body,
div {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

&::-webkit-scrollbar {
  width: 12px;
}

&::-webkit-scrollbar-track {
  background: #a9ffcd;
}

&::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #73f3a8, #00fce3);
}

&::-webkit-scrollbar-thumb:hover {
  background: #f73e75;
}
